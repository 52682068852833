import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeCircle,
    placeAngle,
    placeDash,
    placeLabel,
    placeArc,
    placeLogo,
    placeShuffle,
    placeErase,
    placeTest
} from '../../../common/edliy_utils-fractions';
const Boxes = {
    //JXG.Options.line.highlight=false;
    box1: function () {
    //Create the Board
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-5, 18, 20, -7],
        keepaspectratio: true, axis:false, ticks:{visible:true},
        grid:true, showCopyright:false, showNavigation:false,
        pan:{enabled:false}, zoom:{enabled:false}});

    brd1.suspendUpdate();
    // Layering
    brd1.options.axis.strokeWidth=0;
    brd1.options.layer['image']=15;
    brd1.options.layer['text']=16;
    brd1.options.layer['line']=16;
    brd1.options.layer['point']=17;
    brd1.options.layer['glider']=17;
    brd1.options.layer['angle']=18;
    brd1.options.board.minimizeReflow = 'none';
    brd1.options.point.showInfobox =false;
    brd1.options.line.highlight=false;
    brd1.options.image.highlight=false;
    brd1.options.text.highlight=false;
//    brd1.options.text.display='internal';
    //Make the board responsive
    makeResponsive(brd1);
    // Placing Logo
    placeLogo(brd1);
    //Placing Title
    placeTitle(brd1, 'Arithmetic Mean', '(Find the mean of weights on the three scales)');
    var title1 = placeLeftText(brd1,  8, 13.5, 'Shuffle the weights on the three scales such that:');
    title1.setAttribute({fontSize:18, color:'red'});
//////////////////////////////////////////////////
    var title2 = placeLeftText(brd1,  8.5, 11.5, '1. Every scale holds the same weight.');
    title2.setAttribute({fontSize:18, color:'red'});
////////////////////////////////////////////////////////////
    var title3 = placeLeftText(brd1,  8.5, 9.5, '2. Sum total of original weights remains unchanged.');
    title3.setAttribute({fontSize:18, color:'red'});
///////////////////////////////////////////////////////
    placeMiddleText(brd1, 15, 4.5, 'Total Weight');
    placeMiddleText(brd1, 15, 2.5, ()=>mass1+mass2+mass3);
    // Placing the Eraser Button
    ////////////////////////////
    var analytics =placeShuffle(brd1);
    analytics.setLabel('Tap to Shuffle')
    analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    analytics.on('over', function () {this.label.setAttribute({visible:true});});
    analytics.on('out', function () {this.label.setAttribute({visible:false});});
    /////////////////////////////////////////////
    var erase = placeTest(brd1);
    erase.setLabel('Tap to Test Your Answer')
    erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    erase.on('over', function () {this.label.setAttribute({visible:true});});
    erase.on('out', function () {this.label.setAttribute({visible:false});});
    //FIRST
    const list1 = [2, 1, 1, 2];
    const list2 = [1, 4, 6, 3];
    const list3 = [0, 1, 2, 7];
    var i = 0;
    var mass1=2, mass2=1, mass3=0, num1=2, num2=1, num3=0;
    var width=2;
    var num_height=6;
    var num_width=2;
    var rectLeft=[];
    var rectRight=[];
    var rectMiddle=[];
    /////////////////////////
    var crectLeft=[];
    var crectRight=[];
    var crectMiddle=[];
    /////////////////////////////////////
    var rectLeftVisibility=[];
    var rectRightVisibility=[];
    var rectMiddleVisibility=[];
    /////////////////////////////////////////
    var crectLeftVisibility=[];
    var crectRightVisibility=[];
    var crectMiddleVisibility=[];
    ///////////////////////////////////////////
    var textLeft=[];
    var textRight=[];
    var textMiddle=[];
    var cmx=0,cmy=0;
   ///////////////////////////////////////////////
    for (let y=0; y<num_height; y++)
        {
            let v1 = true;
            let v2 = false;
            let rectyLeft = brd1.create('image', ['/assets/whiteSquare.svg', [-2*width, y*width], [2.01,2.01]], {visible:y>=num1, opacity:0.25, fixed:true});
            let rectyRight = brd1.create('image', ['/assets/whiteSquare.svg', [0.5*width, y*width], [2.01,2.01]], {visible:y>=num2, opacity:0.25, fixed:true});
            let rectyMiddle = brd1.create('image', ['/assets/whiteSquare.svg', [3*width, y*width], [2.01,2.01]], {visible:y>=num3, opacity:0.25, fixed:true});

            let crectyLeft = brd1.create('image', ['/assets/orangeSquare.svg', [-2*width, y*width], [2.01,2.01]], {visible:y<num1, fixed:true});
            let textyLeft = placeMiddleText(brd1, -2*width+0.5*width, y*width+0.5*width, '1 kg');
            textyLeft.setAttribute({visible:y<num1});

            let crectyRight = brd1.create('image', ['/assets/orangeSquare.svg', [0.5*width, y*width], [2.01,2.01]], {visible:y<num2, fixed:true});
            let textyRight = placeMiddleText(brd1, 0.5*width+0.5*width, y*width+0.5*width, '1 kg');
            textyRight.setAttribute({visible:y<num2});
////////////////////////////////////////////
            let crectyMiddle = brd1.create('image', ['/assets/orangeSquare.svg', [3*width, y*width], [2.01,2.01]], {visible:y<num3, fixed:true});
            let textyMiddle = placeMiddleText(brd1, 3*width+0.5*width, y*width+0.5*width, '1 kg');
            textyMiddle.setAttribute({visible:y<num3});
////////////////////////////////////////////////////////////////////////
            rectLeftVisibility.push(y>=num1);
            crectLeftVisibility.push(y<num1);
            rectRightVisibility.push(y>=num2);
            crectRightVisibility.push(y<num2);
            rectMiddleVisibility.push(y>=num3);
            crectMiddleVisibility.push(y<num3);
///////////////////////////////////////////////
            rectyLeft.on('up', function(){changeLeftRectColorxy(y); });
            crectyLeft.on('up', function(){changeLeftRectColorxy(y); });
            rectyRight.on('up', function(){changeRightRectColorxy(y); });
            crectyRight.on('up', function(){changeRightRectColorxy(y); });
            rectyMiddle.on('up', function(){changeMiddleRectColorxy(y); });
            crectyMiddle.on('up', function(){changeMiddleRectColorxy(y); });
            ////////////////////////////////////
            rectLeft.push(rectyLeft);
            rectRight.push(rectyRight);
            rectMiddle.push(rectyMiddle);
            /////////////////////////////
            crectLeft.push(crectyLeft);
            crectRight.push(crectyRight);
            crectMiddle.push(crectyMiddle);
            //////////////////////////////////
            textLeft.push(textyLeft);
            textRight.push(textyRight);
            textMiddle.push(textyMiddle);
            ///////////////////////////////////////
        }
///////////////////////////////////////////////////////////
            var massLeft=1;
            var massMiddle=1;
            var massRight =1;
////////////////////////////////////////////////////////////////
//brd1.create('image', ['/assets/triangle.svg', [7, 12], [1.0, 1.0]], {visible:true, fixed:true, opacity:1});
////////////////////////////////////////////////////////////////////////////////////
    function changeLeftRectColorxy(y)
    {

        let current_visibility1 = rectLeftVisibility[y];
        let current_visibility2 = crectLeftVisibility[y];
        console.log(current_visibility2, current_visibility1);

        if (current_visibility1==true && current_visibility2==false)
        {
            current_visibility1=false;
            current_visibility2=true;

        }
        else{
            current_visibility1=true;
            current_visibility2=false;
        }
        rectLeft[y].setAttribute({visible: current_visibility1});
        crectLeft[y].setAttribute({visible: current_visibility2});
        textLeft[y].setAttribute({visible: current_visibility2});
        rectLeftVisibility[y]=current_visibility1;
        crectLeftVisibility[y]=current_visibility2;
        mass1 =0;
        for (let i=0;i<num_height;i++)
             {
                 if (crectLeftVisibility[i]==true)
                 {
                    mass1+=massLeft;
                //    num1+=1;
                 }
             }
     }

     function changeRightRectColorxy(y)
     {

         let current_visibility1 = rectRightVisibility[y];
         let current_visibility2 = crectRightVisibility[y];

         if (current_visibility1==true && current_visibility2==false)
         {
             current_visibility1=false;
             current_visibility2=true;

         }
         else{
             current_visibility1=true;
             current_visibility2=false;
         }

         rectRight[y].setAttribute({visible: current_visibility1});
         crectRight[y].setAttribute({visible: current_visibility2});
         textRight[y].setAttribute({visible: current_visibility2});
         rectRightVisibility[y]=current_visibility1;
         crectRightVisibility[y]=current_visibility2;
         mass2=0;
         for (let i=0;i<num_height;i++)
              {
                  if (crectRightVisibility[i]==true)
                  {
                     mass2+=massRight;
                  //   num2+=1;
                  }
              }
      }
      function changeMiddleRectColorxy(y)
      {

          let current_visibility1 = rectMiddleVisibility[y];
          let current_visibility2 = crectMiddleVisibility[y];

          if (current_visibility1==true && current_visibility2==false)
          {
              current_visibility1=false;
              current_visibility2=true;

          }
          else{
              current_visibility1=true;
              current_visibility2=false;
          }

          rectMiddle[y].setAttribute({visible: current_visibility1});
          crectMiddle[y].setAttribute({visible: current_visibility2});
          textMiddle[y].setAttribute({visible: current_visibility2});
          rectMiddleVisibility[y]=current_visibility1;
          crectMiddleVisibility[y]=current_visibility2;
          mass3=0;
          for (let i=0;i<num_height;i++)
               {
                   if (crectMiddleVisibility[i]==true)
                   {
                      mass3+=massRight;
                  //    num3+=1;
                   }
               }
       }
    brd1.create('image', ['/assets/red-scale.svg', [-5, -3.83], [4.0, 4.0]], {visible:true, fixed:true, opacity:1});
    var txt1=placeMiddleText(brd1, -3, -2.3, function(){return '' + (mass1).toFixed(0) + ' kg'});
    txt1.setAttribute({color:'white'});
    //////////////////////////////////////
    brd1.create('image', ['/assets/blue-scale.svg', [0, -3.83], [4.0, 4.0]], {visible:true, fixed:true, opacity:1});
    var txt2=placeMiddleText(brd1, 2, -2.3, function(){return '' + (mass2).toFixed(0) + ' kg'});
    txt2.setAttribute({color:'white'});
    ////////////////////////////////////////////
    brd1.create('image', ['/assets/green-scale.svg', [5, -3.83], [4.0, 4.0]], {visible:true, fixed:true, opacity:1});
    var txt3=placeMiddleText(brd1, 7, -2.3, function(){return '' + (mass3).toFixed(0) + ' kg'});
    txt3.setAttribute({color:'white'});
///////////////////////////////////////////////////
    analytics.on('down', function(){
      if(i<list1.length-1)
      {
        i = i+1;
      }
      else{
        i=0;
      }
      num1 = list1[i];
      num2 = list2[i];
      num3 = list3[i];
      mass1 = num1;
      mass2 = num2;
      mass3 = num3;
      for(let y=0;y<num_height;y++){
        rectLeft[y].setAttribute({visible:y>=num1});
        crectLeft[y].setAttribute({visible:y<num1});
        textLeft[y].setAttribute({visible:y<num1});
        rectRight[y].setAttribute({visible:y>=num2});
        crectRight[y].setAttribute({visible:y<num2});
        textRight[y].setAttribute({visible:y<num2});
        rectMiddle[y].setAttribute({visible:y>=num3});
        crectMiddle[y].setAttribute({visible:y<num3});
        textMiddle[y].setAttribute({visible:y<num3});
        rectLeftVisibility[y] = y>=num1;
        crectLeftVisibility[y] = y<num1;
        rectRightVisibility[y] = y>=num2;
        crectRightVisibility[y] = y<num2;
        rectMiddleVisibility[y] = y>=num3;
        crectMiddleVisibility[y] = y<num3;
        wrng.setAttribute({visible:false});
        rght.setAttribute({visible:false})
      }
    });
    var rght = placeImage(brd1,'/assets/check.svg', 14, -2, 2, 0);
    rght.setAttribute({visible:false});
    var wrng = placeImage(brd1,'/assets/cross.svg', 14, -2, 2, 0);
    wrng.setAttribute({visible:false});
    erase.on('down', function(){
      if(mass1 == mass2 && mass2 == mass3){
        rght.setAttribute({visible:true});
        wrng.setAttribute({visible:false})
      }
      else{
        rght.setAttribute({visible:false});
        wrng.setAttribute({visible:true})
      }
    });
    brd1.unsuspendUpdate();
                      },
              }
export default Boxes;
